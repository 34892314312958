/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const Heading = styled.h2``;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const TextSection = ({ data: { heading, subHeading, body, background } }) => {
  return (
    <Wrapper
      css={[
        css`
          background: linear-gradient(
            ${background?.color1?.hex} 0%,
            ${background?.gradient || `0`}%,
            ${background?.color2?.hex || background?.color1?.hex} 100%
          );
          padding: 2rem 0;
        `
      ]}
    >
      <Grid>
        {background?.image && (
          <Image
            image={background?.image?.asset}
            _css={[
              css`
                object-fit: cover;
                width: 100%;
                height: 100%;
              `
            ]}
          />
        )}

        <article
          css={[
            css`
              animation: var(--animation-appear-in);
              text-align: centre;
              grid-column: span 6 / span 6;
              grid-column-start: 4;
            `
          ]}
        >
          {heading && (
            <Heading
              className="h2"
              css={[
                css`
                  ${MEDIA_QUERIES?.desktop} {
                    width: 48rem;
                  }
                `
              ]}
            >
              {heading}
            </Heading>
          )}

          {subHeading && <h3 className="b1">{subHeading}</h3>}

          {body && (
            <p
              css={[
                css`
                  padding: 2rem 0 0;
                  ${MEDIA_QUERIES?.desktop} {
                    padding: 1rem 0 0;
                  }
                `
              ]}
              className="b2"
            >
              {body}
            </p>
          )}
        </article>
      </Grid>
    </Wrapper>
  );
};

/** ============================================================================
 * graphql query
 */
export const query = graphql`
  fragment TextSectionFragment on SanityTextSection {
    _type

    background {
      color1 {
        hex
        title
      }
      color2 {
        hex
        title
      }
      gradient
      image {
        altText
        asset {
          originalFilename
          title
          description
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }

    heading
    subHeading
    body
  }
`;

export default TextSection;
