/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Image, Go, Button, SwiperCarousel } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 0;
  overflow-x: hidden;
  padding: 4rem 0 0;
  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0 0;
  }
`;

const ImageArticleSection = ({
  data: {
    heading,
    subHeading,
    body,
    alignment,
    button,
    images,
    backgroundColor
  }
}) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <Wrapper
      css={[
        css`
          background-color: ${backgroundColor?.hex};
        `
      ]}
    >
      <Grid>
        <article
          css={[
            css`
            grid-column: span 12/ span 12;
            grid-column-start: 1
            text-align: left;
            ${MEDIA_QUERIES?.desktop}{
              grid-column: span 5 / span 5;
              grid-column-start: ${alignment === `left` ? 1 : 8};
            }
            ${MEDIA_QUERIES?.large}{
              ${alignment === `right` && `padding-left: 2rem`};
              ${alignment === `left` && `padding-right: 2rem`};
            }
          `
          ]}
        >
          <header
            css={[
              css`
                padding-top: 3rem;
                padding-bottom: 3rem;
              `
            ]}
          >
            <h4
              css={[
                css`
                  padding-bottom: 1.5rem;
                  color: var(--color-purple);
                `
              ]}
              className="h4"
            >
              {heading}
            </h4>
            <h2
              css={[
                css`
                  width: 80%;
                `
              ]}
              className="h2"
            >
              {subHeading}
            </h2>
          </header>
          <p className="b1">{body}</p>
          <div
            css={[
              css`
                padding: 3rem 0 4rem;
                ${MEDIA_QUERIES?.desktop} {
                  padding: 2rem 0 3rem;
                }
              `
            ]}
          >
            <Go to={button?.url}>
              <Button text={button.text} />
            </Go>
          </div>
        </article>
        {isDesktop && (
          <figure
            css={[
              css`
                grid-column: span 7 / span 7;
                grid-column-start: ${alignment === `left` ? 6 : 1};
                grid-row-start: 1;
                margin-right: ${alignment === `left` ? `-100%` : `0%`};
                margin-left: ${alignment === `right` ? `-100%` : `0%`};
              `
            ]}
          >
            <SwiperCarousel
              _css={[
                css`
                  height: 100%;
                  overflow: hidden;
                `
              ]}
              slideColumnWidth="6"
              autoplay={{ delay: 5000 }}
              slides={images.map((image) => (
                <Image
                  key={image.asset.title}
                  _css={[
                    css`
                      height: 100%;
                    `
                  ]}
                  image={image}
                />
              ))}
            />
          </figure>
        )}
      </Grid>
      {!isDesktop && (
        <Image
          _css={[
            css`
              min-height: 28rem;
            `
          ]}
          image={images?.[0]?.asset}
        />
      )}
    </Wrapper>
  );
};

export const query = graphql`
  fragment ImageArticleSectionFragment on SanityImageArticleSection {
    _type

    backgroundColor {
      hex
      title
    }
    heading
    subHeading
    body
    alignment

    button {
      text
      url
    }

    images {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default ImageArticleSection;
