/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, SectionHeading, Button } from "~components";
import { useApp } from "~hooks/useApp.jsx";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 4rem 0;
  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0;
  }
`;

const ContactSection = ({ data: { heading, background } }) => {
  const { contactEmail, phoneNumber, address } = useApp();
  const { isDesktop } = useWindowDimensions();

  const [serverResponse, setServerResponse] = useState(``);
  const [message, setMessage] = useState(``);
  const [email, setEmail] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);

  const nameFieldsJsx = (
    <>
      <label htmlFor="firstName" className="label">
        First Name <br />
        <input
          className="input"
          type="text"
          name="firstName"
          id="firstName"
          value={firstName}
          required
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
      </label>

      <label htmlFor="lastName" className="label">
        Last Name <br />
        <input
          className="input"
          type="text"
          name="lastName"
          id="lastName"
          value={lastName}
          required
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
      </label>
    </>
  );

  async function onSubmit(e) {
    e.preventDefault();

    const json = {
      firstName,
      lastName,
      email,
      message
    };
    const response = await window
      .fetch(`/api/sendEmail`, {
        method: `POST`,
        headers: {
          "content-type": `application/json`
        },
        body: JSON.stringify(json)
      })
      .then((res) => res.json());
    setServerResponse(
      `Submitted: ${JSON.stringify(json)}
      Recieved: ${JSON.stringify(response)}`
    );
  }

  return (
    <Wrapper
      id="contact"
      css={[
        css`
          background: linear-gradient(
            ${background?.color1?.hex} 0%,
            ${background?.gradient || `0`}%,
            ${background?.color2?.hex || background?.color1?.hex} 100%
          );
        `
      ]}
    >
      <Grid>
        <SectionHeading heading={heading} headingColor="var(--color-white)" />
        <div
          css={[
            css`
              grid-column: span 12 / span 12;
              color: white;
              ${MEDIA_QUERIES?.desktop} {
                grid-column: span 8 / span 8;
                grid-column-start: 3;
              }
            `
          ]}
        >
          <form
            css={[
              css`
                display: flex;
                gap: 1rem;
                flex-direction: column;
                ref = {formRef}
                input,
                textarea {
                  width: 100%;
                  margin: 0.5rem 0 0 0;
                }

                label {
                  width: 100%;
                }
              `
            ]}
            method="post"
            onSubmit={onSubmit}
          >
            {isDesktop ? (
              <div
                css={[
                  css`
                    display: flex;
                    gap: 1rem;
                  `
                ]}
              >
                {nameFieldsJsx}
              </div>
            ) : (
              nameFieldsJsx
            )}
            <label htmlFor="email" className="label">
              Email <br />
              <input
                className="input"
                type="email"
                name="email"
                id="email"
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </label>
            <label htmlFor="message" className="label">
              Message <br />
              <textarea
                className="textarea"
                type="text"
                name="message"
                id="message"
                rows="5"
                value={message}
                required
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                css={[
                  css`
                    min-height: 2rem;
                    resize: vertical;
                  `
                ]}
              />
            </label>
            <button
              type="submit"
              className="button"
              css={[
                css`
                  width: 100%;
                  margin: 1rem auto 0 auto;
                  background-color: white;
                  border-radius: 30px;
                  color: black;
                  padding: 0.75rem 0;
                  ${MEDIA_QUERIES?.desktop} {
                    width: 16rem;
                  }
                `
              ]}
            >
              Send
            </button>
            {/* <div
              css={css`
                color: white;
                height: 4rem;
              `}
            >
              serverResponse: {serverResponse}
            </div> */}
          </form>
        </div>
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ContactSectionFragment on SanityContactSection {
    _type

    background {
      color1 {
        hex
        title
      }
      color2 {
        hex
        title
      }
      gradient
      image {
        altText
        asset {
          originalFilename
          title
          description
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }

    heading
  }
`;

export default ContactSection;
