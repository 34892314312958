/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useRef, useState, useEffect } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, SectionHeading, Image, SwiperCarousel } from "~components";
import {
  GRID_PADDING_REM,
  GRID_PADDING_REM_XS,
  GRID_MIN_MARGIN_REM
} from "~components/_common/Grid/index.jsx";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 4rem 0 0;
  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0;
  }
`;

const clientsSection = ({ data: { heading, body, background, images } }) => {
  const [boxHeight, setBoxHeight] = useState(0);
  const boxRef = useRef(null);
  const { isDesktop } = useWindowDimensions();

  useEffect(() => {
    setBoxHeight(boxRef?.current?.clientHeight);
  }, [boxRef?.current?.clientHeight]);

  const oddImages = images?.filter((v, i) => i % 2 === 0);
  const evenImages = images?.filter((v, i) => i % 2 !== 0);

  return (
    <Wrapper
      css={[
        css`
          background: linear-gradient(
            ${background?.color1?.hex} 0%,
            ${background?.gradient || `0`}%,
            ${background?.color2?.hex || background?.color1?.hex} 100%
          );
          overflow: hidden;
        `
      ]}
    >
      <Grid>
        <SectionHeading heading={heading} headingColor="var(--color-purple)" />

        <div
          css={[
            css`
              grid-column: span 12 / span 12;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 0rem 4rem;
              gap: 2.5rem;
              ${MEDIA_QUERIES?.desktop} {
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 0rem 2rem;
                gap: 5rem 3rem;
                flex-direction: row;
                grid-column: span 10 / span 10;
                grid-column-start: 2;
              }
            `
          ]}
        >
          {images?.map((logo) => (
            <figure
              css={css`
                width: 16rem;
                height: 10rem;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                ${MEDIA_QUERIES?.desktop} {
                  width: 18rem;
                  height: 8rem;
                }

                .gatsby-image-wrapper {
                  width: auto;
                  max-height: 105%;
                  ${MEDIA_QUERIES?.desktop} {
                    max-height: 130%;
                  }
                  img {
                    width: auto;
                    margin: auto;
                  }
                }
              `}
            >
              <Image image={logo} />
            </figure>
          ))}
        </div>
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment clientsSectionFragment on SanityClientsSection {
    _type

    background {
      color1 {
        hex
        title
      }
      color2 {
        hex
        title
      }
      gradient
      image {
        altText
        asset {
          originalFilename
          title
          description
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    heading
    body

    images {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default clientsSection;
