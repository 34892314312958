/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Grid, SectionHeading, Image, Go } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_GAP_REM } from "~components/_common/Grid";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 4rem 0;
  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0;
  }
`;

const BiosSection = ({
  data: { backgroundColor, heading, subHeading, body, bios }
}) => (
  <Wrapper
    css={[
      css`
        background-color: ${backgroundColor?.hex};
      `
    ]}
  >
    <Grid>
      <SectionHeading
        _css={[
          css`
            padding-bottom: 3rem;
            grid-column: span 12 / span 12;
            grid-column-start: 1;
          `
        ]}
        heading={heading}
        body={subHeading}
      />
      <p
        css={[
          css`
            grid-column: span 12 / span 12;
            grid-column-start: 1;
            text-align: center;
            padding-bottom: 4rem;
            ${MEDIA_QUERIES?.desktop} {
              grid-column: span 6 / span 6;
              grid-column-start: 4;
              padding-bottom: 6rem;
            }
          `
        ]}
        className="b1"
      >
        {body}
      </p>

      <ul
        css={[
          css`
            grid-column: span 12 / span 12;
            display: flex;
            gap: 4.5rem;
            padding: 0;
            flex-direction: column;
            ${MEDIA_QUERIES?.desktop} {
              gap: 0;
              flex-direction: row;
              justify-content: space-between;
            }
          `
        ]}
      >
        {bios.map((bio, index) => (
          <li
            key={`${bio.firstName}${bio.lastName}`}
            css={[
              css`
                ${MEDIA_QUERIES?.desktop} {
                  width: calc(${100 / bios?.length}% - ${GRID_GAP_REM}rem);
                }
              `
            ]}
          >
            <Image image={bio.image.asset} />
            <h3
              css={[
                css`
                  color: var(--color-purple);
                  padding: 2rem 0 0.5rem;
                  ${MEDIA_QUERIES?.desktop} {
                    padding: 4rem 0 0.5rem;
                  }
                `
              ]}
              className="h3"
            >{`${bio.firstName} ${bio.lastName}`}</h3>
            <h4 className="hLight">{bio.title}</h4>
            <p
              css={[
                css`
                  border-top: solid 1px var(--color-green);
                  padding: 1rem 0 2rem;
                `
              ]}
              className="b3"
            >
              {bio.description}
            </p>
          </li>
        ))}
      </ul>
    </Grid>
  </Wrapper>
);

export const query = graphql`
  fragment BiosSectionFragment on SanityBiosSection {
    _type

    heading
    subHeading
    body

    backgroundColor {
      hex
      title
    }

    bios {
      image {
        altText

        asset {
          originalFilename
          title
          description

          gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
        }
      }
      firstName
      lastName
      title
      description
    }
  }
`;

export default BiosSection;
