/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { useProjects, useWindowDimensions } from "~hooks";
import {
  Grid,
  SectionHeading,
  Button,
  SwiperCarousel,
  Go,
  projectBlock
} from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const LinkCarouselSection = ({
  data: { heading, body, backgroundColor, button }
}) => {
  const projects = useProjects();
  const { isDesktop } = useWindowDimensions();
  return (
    <Wrapper
      css={[
        css`
          background-color: ${backgroundColor?.hex};
          overflow-x: hidden;
        `
      ]}
    >
      <Grid
        _css={[
          css`
            padding: 4rem 0;
            ${MEDIA_QUERIES?.desktop} {
              padding: 6rem 0;
            }
          `
        ]}
      >
        <SectionHeading
          heading={heading}
          body={body}
          headingColor="var(--color-white)"
          _css={css`
            color: var(--color-white);
          `}
        />

        {isDesktop ? (
          <div
            css={[
              css`
                grid-column: span 12 / span 12;
              `
            ]}
          >
            <SwiperCarousel
              slideColumnWidth="4"
              slides={projects.map((study) => (
                <article
                  key={study.title}
                  css={[
                    css`
                      padding-bottom: 100%;
                    `
                  ]}
                >
                  <projectBlock
                    name={study?.title}
                    image={study?.cardImage?.asset?.url}
                    type={study?.category}
                    link={`/projects/${study?.slug?.current}`}
                    details={study?.details}
                  />
                </article>
              ))}
            />
          </div>
        ) : (
          projects.slice(0, 3).map((study) => (
            <article
              key={study.title}
              css={[
                css`
                  position: relative;
                  grid-column: span 12 / span 12;
                  padding-bottom: 100%;
                  margin: 0 0 1rem;
                `
              ]}
            >
              <projectBlock
                name={study?.title}
                image={study?.cardImage?.asset?.url}
                type={study?.category}
                link={`/projects/${study?.slug?.current}`}
                details={study?.details}
              />
            </article>
          ))
        )}

        {button && (
          <div
            css={[
              css`
                grid-column: span 12 / span 12;
                text-align: center;
                padding: 3rem 0 0;
                ${MEDIA_QUERIES?.desktop} {
                  padding: 6rem 0 0;
                }
              `
            ]}
          >
            <Go to={button?.url}>
              <Button text={button?.text} _css={[css``]} />
            </Go>
          </div>
        )}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment LinkCarouselSectionFragment on SanityLinkCarouselSection {
    _type

    backgroundColor {
      hex
      title
    }
    heading
    body

    button {
      text
      url
    }
  }
`;

export default LinkCarouselSection;
